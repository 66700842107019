import { Directus, TypeMap, Auth, Item } from "@directus/sdk";
import User from "@/types/User";
import Location from "@/types/Location";
import Job from "@/types/Job";
import JobApplications from "@/types/JobApplication";
import JobCategory from "@/types/JobCategory";
import JobSetup from "@/types/JobSetup";
import JobType from "@/types/JobType";
import UserRole from "@/types/UserRole";
import SystemUser from "@/types/SystemUser";
import Territory from "@/types/Territory";
import Field from "@/types/Field";
import Tag from "@/types/Tag";

interface DirectusResponseId {
  id: string;
}

class DirectusDataService {
    private directus: Directus<TypeMap, Auth>;
    private directus_api_url: string;

    public constructor(server: string) {
        this.directus = new Directus(server);
        this.directus_api_url = server;
    }

    public async generateOTP(email:string,password:string) {
      const loginRes=await this.userLogin(email,password);
      const otpGeneration=await this.generateOTPCode();
      await this.directus.items("directus_users").updateOne(loginRes.id, { otp_code:otpGeneration });
      await fetch('https://tbpo-ramp-api-240b8dd84ed0.herokuapp.com/flows/trigger/2df05b2a-814a-4f91-b0a9-fc695c84484f', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: loginRes.id, otp: otpGeneration }),
      });
      this.userLogout();
      return otpGeneration;
    }

    public async generateForgotOTP(email:string) {
      await this.userLogin('ramp-system-registration@transparentbpo.com', 'r3g!str!@co3ddgj2884hdb123jDgjhifndi');
      const itemQuery = { 
        filter:{
          email: {
            _eq: email,
          },
        }, 
        limit: 1,
      };
      const user_found = await this.directus.items("directus_users").readByQuery(itemQuery) as Item;
      if(user_found.data[0]?.id&&user_found.data[0]?.status==='active') {
        const otpGeneration=await this.generateOTPCode();
        await this.directus.items("directus_users").updateOne(user_found.data[0].id, { otp_code:otpGeneration });
        await fetch('https://tbpo-ramp-api-240b8dd84ed0.herokuapp.com/flows/trigger/2df05b2a-814a-4f91-b0a9-fc695c84484f', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: user_found.data[0].id, otp: otpGeneration }),
        });
        this.userLogout();
        return otpGeneration;
      }else{ 
        this.userLogout();
        return "failed"; 
      }
    }

    public async changePass(email:string, password:string) {
      await this.userLogin('ramp-system-registration@transparentbpo.com', 'r3g!str!@co3ddgj2884hdb123jDgjhifndi');
      const itemQuery = { 
        filter:{
          email: {
            _eq: email,
          },
        }, 
        limit: 1,
      };
      const user_found = await this.directus.items("directus_users").readByQuery(itemQuery) as Item;
      if(user_found.data[0]?.id) {
        await this.directus.items("directus_users").updateOne(user_found.data[0].id, { password:password });
        await this.userLogout();
        return "success";
      }else{ 
        await this.userLogout();
        return "failed"; 
      }
    }

    public async resetPass(email:string, password:string) {
      const itemQuery = { 
        filter:{
          email: {
            _eq: email,
          },
        }, 
        limit: 1,
      };
      const user_found = await this.directus.items("directus_users").readByQuery(itemQuery) as Item;
      if(user_found.data[0]?.id) {
        await this.directus.items("directus_users").updateOne(user_found.data[0].id, { password:password, status:'active' });
        return "success";
      }else{ 
        return "failed"; 
      }
    }

    public async generateOTPCode(): Promise<string> { 
      // Declare a digits variable  
      // which stores all digits 
      const digits = '0123456789'; 
      let OTP = ''; 
      for (let i = 0; i < 6; i++ ) { 
          OTP += digits[Math.floor(Math.random() * 10)]; 
      } 
      return OTP; 
  }  
    
    public async userLogin(userEmail: string, userPassword: string): Promise<Item> {
      let user: Item = {};
      const authenticated = await this.directus.auth.login({
        email: userEmail.toLowerCase(),
        password: userPassword,
      });
      if (authenticated) {
        user = await this.returnMe();
      }
      return user;
    }

    public async returnMe() {
      if(await this.directus.auth.token) {
        const userData = await this.directus.users.me.read({ fields: ['*', 'user_role.*', 'role.*','territories.*'] });
        return {
          id: userData.id,
          email: userData.email,
          firstName: userData.first_name,
          lastName: userData.last_name,
          user_role: userData.user_role,
          full_data: userData
        };
      } else {
        return {
          id: 'Not Signed',
          email: 'Not Signed',
          firstName: 'Not Signed',
          lastName: 'Not Signed',
          user_role: [],
          full_data: {} as Item
        };
      }
    }

    public async returnChatNotifications(userData:Item) {
      let resultData = [] as Item;
      if(userData.id!=='Not Signed') {
        //Get Chat Notifications
        let chatQuery = { fields: ["*","job_post.id","job_post.title","job_post.sub_title","user_created.*"],limit: -1 } as { fields?:Array<string>; limit: number; filter?: object };
        if(userData.full_data.role.id==="b6c1cba1-21b6-485d-a2ec-e334c6adac30") // Is Admin
        {
          chatQuery = {
            fields: ["*","job_post.id","job_post.title","job_post.sub_title","user_created.*"],
            filter: {
              _and: [
                {
                  to: {
                    _eq: 'recruiter'
                  }
                },
                {
                  seen: {
                    _eq: false
                  }
                },
              ]
            },
            limit: -1,
          }
        }
        if(userData.full_data.role.id==="cdeb2ade-a307-4f93-9cac-12a47a79b13e") // Is Recruit
        {
          const terrIDs = [] as Array<string>;
          userData.full_data.territories.forEach((element:Item) => {
            terrIDs.push(element.territories_id);
          });
          chatQuery = {
            fields: ["*","job_post.id","job_post.title","job_post.sub_title","user_created.*"],
            filter: {
              _and: [
                {
                  territory: {
                    _in: terrIDs
                  }
                },
                {
                  to: {
                    _eq: "recruiter"
                  }
                },
                {
                  seen: {
                    _eq: false
                  }
                },
              ]
            },
            limit: -1,
          };
        }
        if(userData.full_data.role.id==="377da95c-6518-4715-b32d-ac9825cb42bd") // Is Candidate
        {
          chatQuery = {
            fields: ["*","job_post.id","job_post.title","job_post.sub_title","user_created.*"],
            filter: {
              _and: [
                {
                  to: {
                    _eq: userData.id
                  }
                },
                {
                  seen: {
                    _eq: false
                  }
                },
              ]
            },
            limit: -1,
          };
        }
        const result = await this.directus.items("candidate_application_messages").readByQuery(chatQuery);
        console.log("Chat Notifications",result);
        resultData = result;
      }
      return resultData;
    }
    
    public async returnRoleAccess() {
      const meData= await this.returnMe();
      if(meData.full_data.role!==undefined)
      {
        if(meData.full_data.role.name==="Administrator"){
          return { userRole:"Administrator", userAccess:[] }
        } else if(meData.full_data.role.name==="System User") {
          const accesses = meData.user_role.access;
          const territories=[] as Array<string>;
          meData.full_data.territories.forEach((territory:Item) => {
            territories.push(territory.territories_id);
          });
          return { userRole:"System User", userAccess:accesses, userTerritories:territories }
        } else {
          return { userRole:"Candidate", userAccess:[], userTerritories:[] }
        }
      }else {
        return { userRole:"Guest", userAccess:[], userTerritories:[] }
      }
    }

    public async verifyToken() {
      if(await this.directus.auth.token) {
        return true
      } else {
        return false;
      }
    }

    public async userLogout() {
      localStorage.removeItem('auth_token');
    }

    public async getLocations(filterActives = true): Promise<Location[]> {
      const locations: Location[] = [];
      let itemQuery = { limit: -1 };
      if(filterActives) {
        itemQuery = {
          filter: {
            active: {
              _eq: 1
            }
          },
          limit: -1,
        } as { limit: number; filter?: object };
      }
      const result = await this.directus
                              .items("locations")
                              .readByQuery(itemQuery);
      if(result.data) {
        result.data.forEach((location: Item) => {
          locations.push({
            id: location.id,
            name: location.name,
            description: location.description,
          });
        });
      }
      return locations;
    }

    public async getJobCategories(filterActives = true): Promise<JobCategory[]> {
      const categories: JobCategory[] = [];
      let itemQuery = { limit: -1 };
      if(filterActives) {
        itemQuery = {
          filter: {
            active: {
              _eq: 1
            }
          },
          limit: -1,
        } as { limit: number; filter?: object };
      }
      const result = await this.directus
                              .items("job_categories")
                              .readByQuery(itemQuery);
      if(result.data) {
        result.data.forEach((category: Item) => {
          categories.push({
            id: category.id,
            name: category.name,
            description: category.description,
            status: category.status,
            coverImage: category.cover_image
          });
        });
      }
      return categories;
    }

    public async getJobTypes(filterActives = true): Promise<JobType[]> {
      const types: JobType[] = [];
      let itemQuery = { limit: -1 };
      if(filterActives) {
        itemQuery = {
          filter: {
            active: {
              _eq: 1
            }
          },
          limit: -1,
        } as { limit: number; filter?: object };
      }
      const result = await this.directus
                              .items("job_types")
                              .readByQuery(itemQuery);
      if(result.data) {
        result.data.forEach((type: Item) => {
          types.push({
            id: type.id,
            name: type.name,
            description: type.description,
            status: type.status
          });
        });
      }
      return types;
    }

    public async getJobSetups(filterActives = true): Promise<JobSetup[]> {
      const setups: JobSetup[] = [];
      let itemQuery = { limit: -1 };
      if(filterActives) {
        itemQuery = {
          filter: {
            active: {
              _eq: 1
            }
          },
          limit: -1,
        } as { limit: number; filter?: object };
      }
      const result = await this.directus
                              .items("job_setup")
                              .readByQuery(itemQuery);
      if(result.data) {
        result.data.forEach((setup: Item) => {
          setups.push({
            id: setup.id,
            name: setup.name,
            description: setup.description,
            status: setup.status
          });
        });
      }
      return setups;
    }

    public async getJobsAll(filterActives = true,
      findBySlug?: string, perPage = 10, activePage = 1, findByLocationId = 'all')
    {
      const jobs: Item[] = [];
      const jobQuery = { 
        fields:['*.*','territories.*'],
        filter:{}, 
        limit:perPage, 
        page:activePage 
      };
      // Filter * By Actives
      if(filterActives) {
        jobQuery.filter = { ...jobQuery.filter, status: { _eq: "published" } };
      }
      // Find One By Slug
      if(findBySlug) {
        jobQuery.filter = { ...jobQuery.filter, slug: { _eq: findBySlug } };
        jobQuery.limit = 1;
      }
      // Find * via Location
      if(findByLocationId !== 'all') {
        jobQuery.filter = { ...jobQuery.filter, territory: { id: { _eq: findByLocationId } } };
      }
      // Run Query
      const result = await this.directus
                              .items("job_posts")
                              .readByQuery(jobQuery);

      // Set Query Limit for Counter and Pagination
      jobQuery.limit = -1;
      const totalCounts = this.countQueryItems('job_posts',jobQuery);

      if(result.data) {
        result.data.forEach((job: Item) => {
          jobs.push(job);
        })
      }

      return {
        jobsList:jobs,
        totalCounts:totalCounts,
      }
    }

    public async getJobs(filterActives = true,
      findBySlug?: string, perPage = 10, activePage = 1, findByLocationId = 'all')
    {
      const jobs: Item[] = [];
      const jobQuery = { 
        fields:['*.*', 'territory.*'],
        filter:{}, 
        limit:perPage, 
        page:activePage 
      };
      // Filter * By Actives
      if(filterActives) {
        jobQuery.filter = { ...jobQuery.filter, status: { _eq: "published" }, type: { _neq: "internal" } };
      }
      // Find One By Slug
      if(findBySlug) {
        jobQuery.filter = { ...jobQuery.filter, slug: { _eq: findBySlug }, type: { _neq: "internal" } };
        jobQuery.limit = 1;
      }
      // Find * via Location
      if(findByLocationId !== 'all') {
        jobQuery.filter = { ...jobQuery.filter, territory: { id: { _eq: findByLocationId } }, type: { _neq: "internal" } };
      }
      // Run Query
      const result = await this.directus
                              .items("job_posts")
                              .readByQuery(jobQuery);

      // Set Query Limit for Counter and Pagination
      jobQuery.limit = -1;
      const totalCounts = this.countQueryItems('job_posts',jobQuery);

      if(result.data) {
        result.data.forEach((job: Item) => {
          jobs.push(job);
        })
      }

      return {
        jobsList:jobs,
        totalCounts:totalCounts,
      }
    }

    public async getJobsPartial(filterActives = true,
      findBySlug?: string, perPage = 10, activePage = 1, findByLocationId = 'all')
    {
      const jobs: Item[] = [];
      const jobQuery = { 
        fields:['id','slug', 'name', 'title', 'sub_title', 'type', 'status', 'territory.*','template.id'],
        filter:{}, 
        limit:perPage, 
        page:activePage 
      };
      // Filter * By Actives
      if(filterActives) {
        jobQuery.filter = { ...jobQuery.filter, status: { _eq: "published" }, type: { _neq: "internal" } };
      }
      // Find One By Slug
      if(findBySlug) {
        jobQuery.filter = { ...jobQuery.filter, slug: { _eq: findBySlug }, type: { _neq: "internal" } };
        jobQuery.limit = 1;
      }
      // Find * via Location
      if(findByLocationId !== 'all') {
        jobQuery.filter = { ...jobQuery.filter, territory: { id: { _eq: findByLocationId } }, type: { _neq: "internal" } };
      }
      // Run Query
      const result = await this.directus
                              .items("job_posts")
                              .readByQuery(jobQuery);

      // Set Query Limit for Counter and Pagination
      jobQuery.limit = -1;
      const totalCounts = this.countQueryItems('job_posts',jobQuery);

      if(result.data) {
        result.data.forEach((job: Item) => {
          jobs.push(job);
        })
      }

      return {
        jobsList:jobs,
        totalCounts:totalCounts,
      }
    }

    public async getJobsInternal(filterActives = true,
      findBySlug?: string, perPage = 10, activePage = 1, findByLocationId = 'all')
    {
      const jobs: Item[] = [];
      const jobQuery = { 
        fields:['*.*', 'territory.*'],
        filter:{}, 
        limit:perPage, 
        page:activePage 
      };
      // Filter * By Actives
      if(filterActives) {
        jobQuery.filter = { ...jobQuery.filter, status: { _eq: "published" }, type: { _contains: "internal" } };
      }
      // Find One By Slug
      if(findBySlug) {
        jobQuery.filter = { ...jobQuery.filter, slug: { _eq: findBySlug }, type: { _contains: "internal" } };
        jobQuery.limit = 1;
      }
      // Find * via Location
      if(findByLocationId !== 'all') {
        jobQuery.filter = { ...jobQuery.filter, territory: { id: { _eq: findByLocationId } }, type: { _contains: "internal" } };
      }
      // Run Query
      const result = await this.directus
                              .items("job_posts")
                              .readByQuery(jobQuery);

      // Set Query Limit for Counter and Pagination
      jobQuery.limit = -1;
      const totalCounts = this.countQueryItems('job_posts',jobQuery);

      if(result.data) {
        result.data.forEach((job: Item) => {
          jobs.push(job);
        })
      }

      return {
        jobsList:jobs,
        totalCounts:totalCounts,
      }
    }

    public async getJobsInternalPartial(filterActives = true,
      findBySlug?: string, perPage = 10, activePage = 1, findByLocationId = 'all')
    {
      const jobs: Item[] = [];
      const jobQuery = { 
        fields:['id','slug', 'name', 'title', 'sub_title', 'type', 'status', 'territory.*','template.id'],
        filter:{}, 
        limit:perPage, 
        page:activePage 
      };
      // Filter * By Actives
      if(filterActives) {
        jobQuery.filter = { ...jobQuery.filter, status: { _eq: "published" }, type: { _contains: "internal" } };
      }
      // Find One By Slug
      if(findBySlug) {
        jobQuery.filter = { ...jobQuery.filter, slug: { _eq: findBySlug }, type: { _contains: "internal" } };
        jobQuery.limit = 1;
      }
      // Find * via Location
      if(findByLocationId !== 'all') {
        jobQuery.filter = { ...jobQuery.filter, territory: { id: { _eq: findByLocationId } }, type: { _contains: "internal" } };
      }
      // Run Query
      const result = await this.directus
                              .items("job_posts")
                              .readByQuery(jobQuery);

      // Set Query Limit for Counter and Pagination
      jobQuery.limit = -1;
      const totalCounts = this.countQueryItems('job_posts',jobQuery);

      if(result.data) {
        result.data.forEach((job: Item) => {
          jobs.push(job);
        })
      }

      return {
        jobsList:jobs,
        totalCounts:totalCounts,
      }
    }

    public async countQueryItems(collectionName = '', query = {}) {
      try {
        query = {
          fields: ['id'],
          ...query,
        }
        const counts = await this.directus.items(collectionName).readByQuery(query);
        return counts?.data?.length ?? 0;
      } catch (err) {
        throw "Failed to Count Items";
      }
    }

    public async getApplicantJobs(userId = '', jobs: Job[]): Promise<JobApplications[]> {
      const userJobApplications: Array<JobApplications> = [];
      const result = await this.directus
                              .items("job_applications")
                              .readByQuery({
                                filter: {
                                  user_created: {
                                    _eq: userId,
                                  },
                                },
                              });
      if(result.data) {
        result.data.forEach((jobApplication: Item) => {
          userJobApplications.push({
            id: jobApplication.id,
            job: jobs.find((job: Job) => {
              return job.id === jobApplication.job;
            }),
          });
        });
      }
      return userJobApplications;
    }

    public async userAppliesToJob(userId: string | undefined, jobId: string | undefined) {
      const jobApplication = this.directus.items('job_applications').createOne(
          {
              job: jobId,
              applicant: userId,
              job_application_status: 1,
              status: 'published'
          }
      );
      return jobApplication;
    }

    public async candidateActivation(activation_code:string) {
      try {
        await this.userLogin('ramp-system-registration@transparentbpo.com', 'r3g!str!@co3ddgj2884hdb123jDgjhifndi');
        const itemQuery = { 
          filter:{
            activation_code: {
              _eq: activation_code,
            }
          }, 
          limit: 1,
        };
        const user = await this.directus.items("directus_users").readByQuery(itemQuery) as Item;
        if(user.data)
        {
          if(user.data[0])
          {
            if(user.data[0].id)
            {
              const dataId = user.data[0].id;
              await this.directus.items("directus_users").updateOne(dataId, { status:"active" });
            }
          }
        }
        await this.userLogout();
        return true;
      } catch (error) {
        return false;
      }
    }

    public async candidateRegistration(
      title = '', firstName = '', lastName = '', middleName = '', address = '',
      profilePic: File[] | undefined, email = '', password = '', resume: File[] | undefined
    ) 
    {
      await this.userLogin('ramp-system-registration@transparentbpo.com', 'r3g!str!@co3ddgj2884hdb123jDgjhifndi');
      let validation_result = 'Success';
      let uploadedProfilePic = null;
      let uploadedResume = null;
      // Upload profilePic function Here
      if(profilePic)
      {
        uploadedProfilePic = await this.uploadFile(profilePic,'profile_pics');
      }
      // Upload resume function Here
      if(resume)
      {
        uploadedResume = await this.uploadFile(resume,'resumes');
      }

      const registration = await this.directus.items('directus_users').createOne(
      {
        courtesy_title: title,
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        complete_address: address,
        avatar: uploadedProfilePic,
        email: email,
        password: password,
        resume: uploadedResume,
        role: process.env.VUE_APP_DIRECTUS_API_CANDIDATE_ROLE_KEY,
        status: 'draft'
      });
      if(!registration)
      {
        validation_result = 'Registration Failed';
      }
      await this.userLogout();
      return validation_result;
    }

    public async shareJobViaEmail(name = '', email = '', url = '', job = '') {
      const shared = await this.directus.items('job_shares').createOne(
      {
        email: email,
        name: name,
        sent: 0,
        status: 'published',
        job_url: url,
        job: job
      });
      if(!shared)
      {
        return false;
      }
      else
      {
        return true;
      }
    }

    public async uploadFile(file:File[] | undefined, type:string){
      const formData = new FormData();
      let folder = '';
      if(type == 'profile_pics'){ folder = process.env.VUE_APP_DIRECTUS_API_FILE_FOLDER_AVATAR; }
      if(type == 'resumes'){ folder = process.env.VUE_APP_DIRECTUS_API_FILE_FOLDER_RESUME; }
      if(type == 'territory_flags'){ folder = process.env.VUE_APP_DIRECTUS_API_FILE_FOLDER_FLAG; }
      if(type == 'templates_offers'){ folder = process.env.VUE_APP_DIRECTUS_API_FILE_FOLDER_ATTACHMENTS; }
      if(type == 'offers'){ folder = process.env.VUE_APP_DIRECTUS_API_CANDIDATE_OFFERS; }
      if(type == 'documents'){ folder = process.env.VUE_APP_DIRECTUS_API_CANDIDATE_DOCUMENTS; }
      if(type == 'consents_1'){ folder = process.env.VUE_APP_DIRECTUS_API_CANDIDATE_CONSENTS_1; }
      if(type == 'consents_2'){ folder = process.env.VUE_APP_DIRECTUS_API_CANDIDATE_CONSENTS_2; }

      formData.append("folder", folder);
      if(file!==undefined){
        formData.append("file", file[0]);
      }
      const token = await this.directus.auth.token;
      const uploadResponse = await fetch(this.directus_api_url+'/files', {
        method: "POST",
        body: formData,
        headers: {
          Authorization: "Bearer "+token, // Replace with your Directus API access token
        },
      });

      const uploadData = await uploadResponse.json();

      // Get the uploaded file's ID from the response
      return uploadData.data.id;
    }

    public async updateFile(file:File[], type:string, fileIdToUpdate:string, nameTitle:string){

      let folder = '';
      if(type == 'profile_pics'){ folder = process.env.VUE_APP_DIRECTUS_API_FILE_FOLDER_AVATAR; }
      if(type == 'resumes'){ folder = process.env.VUE_APP_DIRECTUS_API_FILE_FOLDER_RESUME; }
      if(type == 'territory_flags'){ folder = process.env.VUE_APP_DIRECTUS_API_FILE_FOLDER_FLAG; }
      if(type == 'templates_offers'){ folder = process.env.VUE_APP_DIRECTUS_API_FILE_FOLDER_ATTACHMENTS; }
      if(type == 'offers'){ folder = process.env.VUE_APP_DIRECTUS_API_CANDIDATE_OFFERS; }
      if(type == 'documents'){ folder = process.env.VUE_APP_DIRECTUS_API_CANDIDATE_DOCUMENTS; }

      if(fileIdToUpdate===null) {
        const uploadData = await this.uploadFile(file, type);
        return uploadData;
      } else {
        const fileExist = await this.directus.files.readOne(fileIdToUpdate);
        if (fileExist) {
          const formData = new FormData();
          formData.append("filename_download", file[0].name);
          formData.append("title", nameTitle);
          formData.append("folder", folder);
          formData.append("file", file[0]);
          const token = await this.directus.auth.token;
          const uploadResponse = await fetch(this.directus_api_url+'/files/'+fileIdToUpdate, {
            method: "PATCH",
            body: formData,
            headers: {
              Authorization: "Bearer "+token, // Replace with your Directus API access token
            },
          });

          const uploadData = await uploadResponse.json();

          // Get the uploaded file's ID from the response
          return uploadData.data.id;
        }
      }
    }

    public async listRoles(id = '', status = 'published') {
      const roleData: UserRole[] = [];
      const itemQuery = { 
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        itemQuery.filter = {
          id: {
            _eq: id,
          },
          status: {
            _eq: status,
          }
        }
      }
      else
      {
        itemQuery.filter = {
          status: {
            _eq: status,
          }
        }
      }
      const roles = await this.directus.items("user_roles").readByQuery(itemQuery);
      if(roles.data) {
        roles.data.forEach((role: Item) => {
          roleData.push({
            id: role.id,
            status: role.status,
            name: role.name,
            access: role.access,
            description: role.description,
            field_level: role.field_level,
          });
        });
      }
      return roleData;
    }

    public async createRole(roleFields:UserRole) {
      try {
        await this.directus.items("user_roles").createOne(roleFields);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async updateRole(roleFields:UserRole, dataId:string) {
      console.log(roleFields);
      try {
        await this.directus.items("user_roles").updateOne(dataId, roleFields);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async listSystemUsers(id = '', status = 'active' , isCandidate = false) {
      const userData: SystemUser[] = [];
      let role_id='';
      if(isCandidate){ role_id='377da95c-6518-4715-b32d-ac9825cb42bd'; }else{ role_id='cdeb2ade-a307-4f93-9cac-12a47a79b13e'; }
      const itemQuery = { 
        fields:['*','user_role.*', 'territories.*'],
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        itemQuery.filter = {
          id: {
            _eq: id,
          },
          status: {
            _eq: status,
          },
          role: {
            _eq: role_id,
          }
        }
      }
      else
      {
        itemQuery.filter = {
          status: {
            _eq: status,
          },
          role: {
            _eq: role_id,
          }
        }
      }
      const users = await this.directus.items("directus_users").readByQuery(itemQuery);
      if(users.data) {
        users.data.forEach((user: Item) => {
          userData.push({
            id: user.id,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            middle_name: user.middle_name,
            full_name: user.first_name + ' ' + user.last_name,
            preferred_name: user.preferred_name,
            contact_number: user.contact_number,
            avatar: user.avatar,
            social_fb: user.social_fb,
            social_viber: user.social_viber,
            social_whatsapp: user.social_whatsapp,
            social_twitter: user.social_twitter,
            social_linkedin: user.social_linkedin,
            social_website: user.social_website,
            status: user.status,
            user_role: user.user_role,
            territories: user.territories,
            user_territories: [] as Array<string>,
            forms_completed: user.forms_completed,
          });
        });
      }
      return userData;
    }
    public async getAvatar(key: string) {
      const itemQuery = { 
        filter:{
          id: {
            _eq: key,
          },
        },
      };
      const avatar = await this.directus.items("directus_files").readByQuery(itemQuery);
      return avatar;
    }
    
    public async createUser(userData: Item, avatar:File[] | undefined) {
      try {
        if(avatar!==undefined)
        {
          const uploadedAvatar = await this.uploadFile(avatar,'profile_pics');
          if(uploadedAvatar)
          {
            userData.avatar=uploadedAvatar;
          }
          else
          {
            delete userData.avatar;
          }
        }
        else
        {
          delete userData.avatar;
        }
        const territories = userData.territories;
        const newTerritories =[] as Item[];
        territories.forEach((territory: Item) => {
          newTerritories.push({ territories_id: territory })
        });
        delete userData.territories;
        userData.territories = newTerritories;
        await this.directus.items("directus_users").createOne(userData);
        
        return true;
      } catch (error) {
        return false; 
      }
    }

    public async updateUser(userData: Item, avatar:File[] | undefined, userId:string) {
      try {
        if(avatar){
          const avatarID = await this.updateFile(avatar,'profile_pics', userData.avatar, userData.first_name+' '+userData.last_name);
          userData.avatar = avatarID;
        }
        const territories = userData.territories;
        const newTerritories =[] as Item[];
        territories.forEach((territory: Item) => {
          newTerritories.push({ territories_id: territory })
        });
        delete userData.territories;
        if(!userData.password){
          delete userData.password;
        }
        userData.territories = newTerritories;
        console.log('Before Update',userData);
        await this.directus.items("directus_users").updateOne(userId,userData);
        console.log('Updated User',userData);
        return true;
      } catch (error) {
        console.log('Failed User',error);
        return false;
      }
    }

    public async updateCandidateData(userData: Item, avatar:File[] | undefined, userId:string, customFields:Item) {
      try {
        //console.log("candidateFieldData",customFields ,userData);
        delete userData.id;
        if(customFields){
          const itemQuery = { 
            filter:{
              candidate: {
                _eq: userId,
              },
            },
          };
          const getAvailableFields = await this.directus.items("field_values").readByQuery(itemQuery) as Item;
          const listAvailableFields = getAvailableFields.data as Item[];
          //console.log("listAvailableFields",listAvailableFields);
          //console.log("customFields",customFields);
          let fieldFound = {} as Item;
          const addFieldPromises = [];
          const itemsToAdd = [] as Item[];
          for (const fieldID of Object.keys(customFields)) {
            [fieldFound]=listAvailableFields.filter((obj:Item) => obj.field === fieldID);
            if(fieldID!==""&&customFields[fieldID]!=="")
            {
              if(!fieldFound){ 
                //this.addFieldData(userId,fieldID,customFields[fieldID])
                //addFieldPromises.push(this.addFieldData(userId, fieldID, customFields[fieldID]));
                itemsToAdd.push({ field: fieldID, candidate: userId, value: customFields[fieldID], status: 'published' });
                //console.log(customFields[fieldID]);
              }else{
                addFieldPromises.push(this.updateFieldData(fieldFound.id,customFields[fieldID]));
                //this.updateFieldData(fieldFound.id,customFields[fieldID])
              }
            }
          }
          addFieldPromises.push(this.directus.items('field_values').createMany(itemsToAdd));
          await Promise.all(addFieldPromises)
          .then(() => {
              // All promises have been initiated
              console.log('All addFieldData calls have been triggered');
          })
          .catch((error) => {
              console.error('Error triggering addFieldData calls:', error);
          });
        }
        if(avatar){
          const avatarID = await this.updateFile(avatar,'profile_pics', userData.avatar, userData.first_name+' '+userData.last_name);
          userData.avatar = avatarID;
        }
        if(!userData.password){
          delete userData.password;
        }
        //console.log('Before Update',userData);
        await this.directus.items("directus_users").updateOne(userId,userData);
        //console.log('Updated User',userData);
        return true;
      } catch (error) {
        //console.log('Failed User',error);
        return false;
      }
    }

    public async updateItemStatus(statusUpdate:string, collection:string, userId:string) {
      try {
        await this.directus.items(collection).updateOne(userId,{ status:statusUpdate });
        return true;
      } catch (error) {
        return false;
      }
    }

    public async createTerritory(territoryFields:Territory, flag:File[] | undefined) {
      try {
        if(flag)
        {
          if(territoryFields.active === true)
          {
            territoryFields.status = 'published';
          }
          else
          {
            territoryFields.status = 'archived';
          }
          const uploadedAvatar = await this.uploadFile(flag,'territory_flags');
          territoryFields.flag = uploadedAvatar;
          await this.directus.items("territories").createOne(territoryFields);
        }
        return true;
      } catch (error) {
        return false;
      }
    }

    public async updateTerritory(territoryFields:Item, flag:File[] | undefined, dataId:string) {
      try {
        if(flag){
          await this.updateFile(flag,'territory_flags', territoryFields.flag, territoryFields.name);
        }
        await this.directus.items("territories").updateOne(dataId, territoryFields);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async returnTerritoryID(name:string) {
      let territoryID = "";
      const itemQuery = { 
        filter:{
          name: {
            _eq: name.charAt(0).toUpperCase() + name.slice(1),
          },
          status: {
            _eq: 'published',
          }
        }, 
        limit: -1,
      };
      const territories = await this.directus.items("territories").readByQuery(itemQuery) as Item;
      if(territories.data) {
        const [terrID] = territories.data;
        territoryID = terrID.id;
      }
      return territoryID;
    }

    public async getTerritories(id = '', status = 'published') {
      const territoryData: Item[] = [];
      const itemQuery = { 
        fields:['*', 'templates_candidates.*'],
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        itemQuery.filter = {
          id: {
            _eq: id,
          },
          status: {
            _eq: status,
          }
        }
      }
      else
      {
        itemQuery.filter = {
          status: {
            _eq: status,
          }
        }
      }
      const territories = await this.directus.items("territories").readByQuery(itemQuery);
      if(territories.data) {
        territories.data.forEach((territory: Item) => {
          territoryData.push({
            id: territory.id,
            name: territory.name,
            description: territory.description,
            active: territory.active,
            status: territory.status,
            flag: territory.flag,
            candidate_template: territory.candidate_template,
          });
        });
      }
      return territoryData;
    }

    public async listFields(id = '', status = 'published') {
      const fieldData: Field[] = [];
      const itemQuery = { 
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        itemQuery.filter = {
          id: {
            _eq: id,
          },
          status: {
            _eq: status,
          }
        }
      }
      else
      {
        itemQuery.filter = {
          status: {
            _eq: status,
          }
        }
      }
      const fields = await this.directus.items("fields").readByQuery(itemQuery);
      if(fields.data) {
        fields.data.forEach((field: Item) => {
          fieldData.push({
            id: field.id,
            status: field.status,
            name: field.name,
            description: field.description,
            type: field.type,
            options: field.options,
            active: field.active,
            field_key: field.field_key,
            editable: field.editable,
            view_level: field.view_level
          });
        });
      }
      return fieldData;
    }
    
    public async createField(fieldData:Field) {
      try {
        if(fieldData.active === true)
        {
          fieldData.status = 'published';
        }
        else
        {
          fieldData.status = 'archived';
        }
        await this.directus.items("fields").createOne(fieldData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async updateField(fieldData:Field, fieldID:string) {
      try {
        if(fieldData.active === true)
        {
          fieldData.status = 'published';
        }
        else
        {
          fieldData.status = 'archived';
        }
        await this.directus.items("fields").updateOne(fieldID,fieldData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async listTags(id = '', status = 'published') {
      const tagData: Tag[] = [];
      const itemQuery = { 
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        itemQuery.filter = {
          id: {
            _eq: id,
          },
          status: {
            _eq: status,
          }
        }
      }
      else
      {
        itemQuery.filter = {
          status: {
            _eq: status,
          }
        }
      }
      const tags = await this.directus.items("tags").readByQuery(itemQuery);
      if(tags.data) {
        tags.data.forEach((tag: Item) => {
          tagData.push({
            id: tag.id,
            status: tag.status,
            name: tag.name,
            description: tag.description,
          });
        });
      }
      return tagData;
    }

    public async createTag(tagData:Tag) {
      try {
        await this.directus.items("tags").createOne(tagData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async updateTag(tagData:Field, tagID:string) {
      try {
        await this.directus.items("tags").updateOne(tagID,tagData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async listTemplateEmails(id = '', status = 'published') {
      const terrQuery = { 
        fields:['*'],
        limit: -1,
      };
      const territoryAvailable = [] as Item;
      const territoryList = await this.directus.items("territories").readByQuery(terrQuery);
      if(territoryList.data) {
        territoryList.data.forEach((territory: Item) => {
          territoryAvailable.push({
            id: territory.id,
            name: territory.name,
          })
        })
      }
      const emailData: Item[] = [];
      const itemQuery = { 
        fields:['*', 'territories.*'],
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        itemQuery.filter = {
          id: {
            _eq: id,
          },
          status: {
            _eq: status,
          }
        }
      }
      else
      {
        const roleAccess=await this.returnRoleAccess();
        if(roleAccess.userRole==="System User") {
          itemQuery.filter = {
            "territories": {
              "territories_id": {
                "_in": roleAccess.userTerritories
              }
            },
            status: {
              _eq: status,
            }
          }
        } else {
          itemQuery.filter = {
            status: {
              _eq: status,
            }
          }
        }
      }
      const emails = await this.directus.items("templates_emails").readByQuery(itemQuery);
      if(emails.data) {
        emails.data.forEach((email: Item) => {
          const systemLocations = [] as Item;
          email.territories.forEach((terr: Item) => {
            const foundItem = territoryAvailable.find((item:Item) => item.id === terr.territories_id);
            systemLocations.push(foundItem.name);
          });
          emailData.push({
            id: email.id,
            status: email.status,
            name: email.name,
            description: email.description,
            template: email.template,
            territories: email.territories,
            locations: systemLocations,
            custom_name: "("+systemLocations+")"+ email.name,
          });
        });
      }
      return emailData;
    }

    public async createTemplateEmails(emailData:Item) {
      try {
        const territories = emailData.territories;
        const newTerritories =[] as Item[];
        territories.forEach((territory: Item) => {
          newTerritories.push({ territories_id: territory })
        });
        delete emailData.territories;
        emailData.territories = newTerritories;
        await this.directus.items("templates_emails").createOne(emailData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async updateTemplateEmails(emailData:Item, emailID:string) {
      try {
        const territories = emailData.territories;
        const newTerritories =[] as Item[];
        territories.forEach((territory: Item) => {
          newTerritories.push({ territories_id: territory })
        });
        delete emailData.territories;
        emailData.territories = newTerritories;
        await this.directus.items("templates_emails").updateOne(emailID,emailData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async listTemplateOffers(id = '', status = 'published') {
      const offerData: Item[] = [];
      const itemQuery = { 
        fields:['*', 'territories.*'],
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        itemQuery.filter = {
          id: {
            _eq: id,
          },
          status: {
            _eq: status,
          }
        }
      }
      else
      {
        const roleAccess=await this.returnRoleAccess();
        if(roleAccess.userRole==="System User") {
          itemQuery.filter = {
            "territories": {
              "territories_id": {
                "_in": roleAccess.userTerritories
              }
            },
            status: {
              _eq: status,
            }
          }
        } else {
          itemQuery.filter = {
            status: {
              _eq: status,
            }
          }
        }
      }
      const offers = await this.directus.items("templates_offers").readByQuery(itemQuery);
      if(offers.data) {
        offers.data.forEach((offer: Item) => {
          offerData.push({
            id: offer.id,
            status: offer.status,
            name: offer.name,
            description: offer.description,
            template: offer.template,
            territories: offer.territories,
            attachment: offer.attachment,
            pdf_template: offer.pdf_template,
            form_template: offer.form_template,
          });
        });
      }
      return offerData;
    }

    public async createTemplateOffers(offerData:Item, attachment:File[] | undefined) {
      try {
        const territories = offerData.territories;
        const newTerritories =[] as Item[];
        territories.forEach((territory: Item) => {
          newTerritories.push({ territories_id: territory })
        });
        delete offerData.territories;
        offerData.territories = newTerritories;
        if(attachment) {
          const uploadedAttachment = await this.uploadFile(attachment,'templates_offers');
          if(uploadedAttachment)
          {
            offerData.attachment=uploadedAttachment;
          }
        }
        await this.directus.items("templates_offers").createOne(offerData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async updateTemplateOffers(offerData:Item, attachment:File[] | undefined, offerID:string) {
      try {
        const territories = offerData.territories;
        const newTerritories =[] as Item[];
        territories.forEach((territory: Item) => {
          newTerritories.push({ territories_id: territory })
        });
        delete offerData.territories;
        offerData.territories = newTerritories;
        if(attachment) {
          await this.updateFile(attachment,'templates_offers', offerData.attachment, offerData.name);
        }
        await this.directus.items("templates_offers").updateOne(offerID,offerData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async listTemplateConsents(id = '', status = 'published') {
      const consentData: Item[] = [];
      const itemQuery = { 
        fields:['*', 'territories.*'],
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        itemQuery.filter = {
          id: {
            _eq: id,
          },
          status: {
            _eq: status,
          }
        }
      }
      else
      {
        const roleAccess=await this.returnRoleAccess();
        if(roleAccess.userRole==="System User") {
          itemQuery.filter = {
            "territories": {
              "territories_id": {
                "_in": roleAccess.userTerritories
              }
            },
            status: {
              _eq: status,
            }
          }
        } else {
          itemQuery.filter = {
            status: {
              _eq: status,
            }
          }
        }
      }
      const consents = await this.directus.items("template_consent_form").readByQuery(itemQuery);
      if(consents.data) {
        consents.data.forEach((consent: Item) => {
          consentData.push({
            id: consent.id,
            status: consent.status,
            name: consent.name,
            description: consent.description,
            template: consent.template,
            territories: consent.territories,
            pdf_template: consent.pdf_template,
            form_template: consent.form_template,
            send_to_bi: consent.send_to_bi,
            bi_email: consent.bi_email,
          });
        });
      }
      return consentData;
    }

    public async createTemplateConsents(consentData:Item) {
      try {
        const territories = consentData.territories;
        const newTerritories =[] as Item[];
        territories.forEach((territory: Item) => {
          newTerritories.push({ territories_id: territory })
        });
        delete consentData.territories;
        consentData.territories = newTerritories;
        await this.directus.items("template_consent_form").createOne(consentData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async updateTemplateConsents(consentData:Item, consentID:string) {
      try {
        const territories = consentData.territories;
        const newTerritories =[] as Item[];
        territories.forEach((territory: Item) => {
          newTerritories.push({ territories_id: territory })
        });
        delete consentData.territories;
        consentData.territories = newTerritories;
        await this.directus.items("template_consent_form").updateOne(consentID,consentData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async createTemplateCandidatesForms(candidateData:Item) {
      try {
        await this.directus.items("templates_candidates_forms").createOne(candidateData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async updateTemplateCandidatesForms(candidateData:Item, templateID:string) {
      try {
        await this.directus.items("templates_candidates_forms").updateOne(templateID,candidateData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async listTemplateCandidatesForms(id = '', status = 'published') {
      const candidateData: Item[] = [];
      const itemQuery = { 
        fields:['*'],
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        itemQuery.filter = {
          id: {
            _eq: id,
          },
          status: {
            _eq: status,
          }
        }
      }
      else
      {
        itemQuery.filter = {
          status: {
            _eq: status,
          }
        }
      }
      const candidates = await this.directus.items("templates_candidates_forms").readByQuery(itemQuery);
      if(candidates.data) {
        candidates.data.forEach((candidate: Item) => {
          candidateData.push({
            id: candidate.id,
            status: candidate.status,
            name: candidate.name,
            description: candidate.description,
            required: candidate.required,
            template: candidate.template,
            territories: candidate.territories,
          });
        });
      }
      return candidateData;
    }

    public async createTemplateCandidates(candidateData:Item) {
      try {
        const territories = candidateData.territories;
        const newTerritories =[] as Item[];
        territories.forEach((territory: Item) => {
          newTerritories.push({ territories_id: territory })
        });
        delete candidateData.territories;
        candidateData.territories = newTerritories;
        await this.directus.items("templates_candidates").createOne(candidateData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async updateTemplateCandidates(candidateData:Item, templateID:string) {
      try {
        const territories = candidateData.territories;
        const newTerritories =[] as Item[];
        territories.forEach((territory: Item) => {
          newTerritories.push({ territories_id: territory })
        });
        delete candidateData.territories;
        candidateData.territories = newTerritories;
        await this.directus.items("templates_candidates").updateOne(templateID,candidateData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async listTemplateCandidates(id = '', status = 'published', territory = '') {
      const candidateData: Item[] = [];
      const itemQuery = { 
        fields:['*', 'territories.*', 'territories.id'],
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        itemQuery.filter = {
          id: {
            _eq: id,
          },
          status: {
            _eq: status,
          }
        }
      }
      else
      {
        if(territory !== '') {
          itemQuery.filter = {
            status: {
              _eq: status,
            },
          territories: {
              territories_id: {
                _eq: territory
              }
            }
          }
        } else {
          const roleAccess=await this.returnRoleAccess();
          if(roleAccess.userRole==="System User") {
            itemQuery.filter = {
              "territories": {
                "territories_id": {
                  "_in": roleAccess.userTerritories
                }
              },
              status: {
                _eq: status,
              }
            }
          } else {
            itemQuery.filter = {
              status: {
                _eq: status,
              }
            }
          }
        }
      }
      const candidates = await this.directus.items("templates_candidates").readByQuery(itemQuery);
      if(candidates.data) {
        candidates.data.forEach((candidate: Item) => {
          candidateData.push({
            id: candidate.id,
            status: candidate.status,
            name: candidate.name,
            description: candidate.description,
            template: candidate.template,
            territories: candidate.territories,
          });
        });
      }
      return candidateData;
    }

    public async createTemplateJobs(jobData:Item) {
      try {
        //Territory Junc
        const territories = jobData.territories;
        const newTerritories =[] as Item[];
        territories.forEach((territory: Item) => {
          newTerritories.push({ territories_id: territory })
        });
        delete jobData.territories;
        jobData.territories = newTerritories;

        //Stages Junc
        const stages = jobData.stages;
        const newStages =[] as Item[];
        stages.forEach((stage: Item) => {
          newStages.push({ stages_id: stage.id })
        });
        delete jobData.stages;
        jobData.stages = newStages;
        await this.directus.items("templates_jobs").createOne(jobData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async updateTemplateJobs(jobData:Item, templateID:string) {
      try {
        //Territory Junc
        const territories = jobData.territories;
        const newTerritories =[] as Item[];
        territories.forEach((territory: Item) => {
          newTerritories.push({ territories_id: territory })
        });
        delete jobData.territories;
        jobData.territories = newTerritories;

        //Stages Junc
        const stages = jobData.stages;
        const newStages =[] as Item[];
        stages.forEach((stage: Item) => {
          newStages.push({ stages_id: stage.id })
        });
        delete jobData.stages;
        jobData.stages = newStages;
        await this.directus.items("templates_jobs").updateOne(templateID,jobData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async updateTemplateJobsPipe(elements:Item, edges:Item, templateID:string, last_id:number, last_y:number) {
      try {
        const templateData={ id:templateID, pipeline: elements, edges: edges,last_id:last_id, last_y:last_y };
        await this.directus.items("templates_jobs").updateOne(templateID,templateData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async listTemplateJobs(id = '', status = 'published', territory = '') {
      const terrQuery = { 
        fields:['*'],
        limit: -1,
      };
      const territoryAvailable = [] as Item;
      const territoryList = await this.directus.items("territories").readByQuery(terrQuery);
      if(territoryList.data) {
        territoryList.data.forEach((territory: Item) => {
          territoryAvailable.push({
            id: territory.id,
            name: territory.name,
          })
        })
      }
      const jobData: Item[] = [];
      const itemQuery = { 
        fields:['*', 'territories.*','stages.*','territories.name'],
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        itemQuery.filter = {
          id: {
            _eq: id,
          },
          status: {
            _eq: status,
          }
        }
      }
      else
      {
        if(territory !== '') {
          itemQuery.filter = {
            status: {
              _eq: status,
            },
            territory: {
              _has: territory,
            }
          }
        }
        else {
          const roleAccess=await this.returnRoleAccess();
          if(roleAccess.userRole==="System User") {
            itemQuery.filter = {
              "territories": {
                "territories_id": {
                  "_in": roleAccess.userTerritories
                }
              },
              status: {
                _eq: status,
              }
            }
          } else {
            itemQuery.filter = {
              status: {
                _eq: status,
              }
            }
          }
        }
      }
      const jobs = await this.directus.items("templates_jobs").readByQuery(itemQuery);
      if(jobs.data) {
        jobs.data.forEach((job: Item) => {
          const systemLocations = [] as Item;
          job.territories.forEach((terr: Item) => {
            const foundItem = territoryAvailable.find((item:Item) => item.id === terr.territories_id);
            systemLocations.push(foundItem.name);
          });
          jobData.push({
            id: job.id,
            status: job.status,
            name: job.name,
            description: job.description,
            template: job.template,
            territories: job.territories,
            pipeline: job.pipeline,
            edges: job.edges,
            last_id:job.last_id,
            last_y:job.last_y,
            stages:job.stages,
            closer_val:job.closer_val,
            locations: systemLocations,
          });
        });
      }
      return jobData;
    }

    public async createJobs(jobData:Item) {
      try {
        const result = await this.directus.items("job_posts").createOne(jobData);
        return result;
      } catch (error) {
        return 'error';
      }
    }

    public async updateJobs(jobId:string,jobData:Item) {
      try {
        console.log("DS jobData", jobData);
        delete jobData.id;
        const result = await this.directus.items("job_posts").updateOne(jobId,jobData);
        return result;
      } catch (error) {
        return 'error';
      }
    }

    public async updateJobPipelines(jobId:string, pipeline:Item, edges:Item, last_y:number, last_id:number) {
      try {
        const jobData = {
          pipeline:pipeline,
          edges:edges,
          last_y:last_y,
          last_id:last_id
        };
        const result = await this.directus.items("job_posts").updateOne(jobId,jobData);
        return result;
      } catch (error) {
        return 'error';
      }
    }

    public async listJobs(id = '', status = 'published', territory = '', listAll = '',tbpo_employee = false) {
      const roleAccess=await this.returnRoleAccess();
      const jobData: Item[] = [];
      const itemQuery = { 
        fields:['*', 'territory.*'],
        filter:{}, 
        limit: -1,
      };
      if(listAll !=='')
      {
        if(id !== '')
        {
          itemQuery.filter = {
            id: {
              _eq: id,
            },
          }
        }
        else
        {
          if(territory !== '') {
            itemQuery.filter = {
              status: {
                _eq: status,
              },
              "territory": {
                "_eq": territory
              },
            }
          } else {
            const roleAccess=await this.returnRoleAccess();
            if(roleAccess.userRole==="System User") {
              itemQuery.filter = {
                "territory": {
                  "_in": roleAccess.userTerritories
                },
                status: {
                  _eq: status,
                }
              }
            } else {
              if(tbpo_employee===true){
                itemQuery.filter = {
                  status: {
                    _eq: status,
                  }
                }
              }else{
                itemQuery.filter = {
                  status: {
                    _eq: status,
                  },
                  type: {
                    _neq: 'internal',
                  }
                }
              }
            }
          }
        }
      } else {
        if(id !== '')
        {
          itemQuery.filter = {
            id: {
              _eq: id,
            },
          }
        }
        else
        {
          const roleAccess=await this.returnRoleAccess();
          if(roleAccess.userRole==="System User") {
            itemQuery.filter = {
              "territory": {
                "_in": roleAccess.userTerritories
              },
              status: {
                _eq: status,
              }
            }
          }else{
            itemQuery.filter = {
              status: {
                _eq: status,
              },
            }
          }
        }
      }
      console.log(itemQuery)
      const jobs = await this.directus.items("job_posts").readByQuery(itemQuery);
      if(jobs.data) {
        jobs.data.forEach((job: Item) => {
          jobData.push({
            id: job.id,
            status: job.status,
            slug: job.slug,
            title: job.title,
            sub_title: job.sub_title,
            vacancy: job.vacancy,
            vacancy_filled: job.vacancy_filled,
            territory: job.territory,
            territory_description: job.territory_description,
            description: job.description,
            sub_description: job.sub_description,
            requirements: job.requirements,
            vacancy_description: job.vacancy_description,
            contact: job.contact,
            share: job.share,
            pipeline: job.pipeline,
            edges: job.edges,
            template: job.template,
            last_y: job.last_y,
            last_id: job.last_id,
            form: job.form,
            closer_val: job.closer_val,
            type: job.type,
            layout: job.layout
          });
        });
      }
      return jobData;
    }

    public async listJobsPartialInfo(id = '', status = 'published', territory = '', listAll = '',tbpo_employee = false) {
      const jobData: Item[] = [];
      const itemQuery = { 
        fields:['id','slug', 'name', 'title', 'sub_title', 'type', 'status', 'territory.*'],
        filter:{}, 
        limit: -1,
      };
      if(listAll !=='')
      {
        if(id !== '')
        {
          itemQuery.filter = {
            id: {
              _eq: id,
            },
          }
        }
        else
        {
          if(territory !== '') {
            itemQuery.filter = {
              status: {
                _eq: status,
              },
              "territory": {
                "_eq": territory
              },
            }
          } else {
            const roleAccess=await this.returnRoleAccess();
            if(roleAccess.userRole==="System User") {
              itemQuery.filter = {
                "territory": {
                  "_in": roleAccess.userTerritories
                },
                status: {
                  _eq: status,
                }
              }
            } else {
              if(tbpo_employee===true){
                itemQuery.filter = {
                  status: {
                    _eq: status,
                  }
                }
              }else{
                itemQuery.filter = {
                  status: {
                    _eq: status,
                  },
                  type: {
                    _neq: 'internal',
                  }
                }
              }
            }
          }
        }
      } else {
        if(id !== '')
        {
          itemQuery.filter = {
            id: {
              _eq: id,
            },
          }
        }
        else
        {
          itemQuery.filter = {
            status: {
              _eq: status,
            },
          }
        }
      }
      const jobs = await this.directus.items("job_posts").readByQuery(itemQuery);
      if(jobs.data) {
        jobs.data.forEach((job: Item) => {
          jobData.push({
            id: job.id,
            status: job.status,
            slug: job.slug,
            title: job.title,
            sub_title: job.sub_title,
            territory: job.territory,
            type: job.type,
          });
        });
      }
      return jobData;
    }

    public async listJobsApplied() {
      const jobData: Item[] = [];
      const itemQuery = { 
        fields:['*', 'territory.*'],
        filter:{}, 
        limit: -1,
      };
      const jobs = await this.directus.items("job_posts").readByQuery(itemQuery);
      if(jobs.data) {
        jobs.data.forEach((job: Item) => {
          jobData.push({
            id: job.id,
            status: job.status,
            slug: job.slug,
            title: job.title,
            sub_title: job.sub_title,
            vacancy: job.vacancy,
            vacancy_filled: job.vacancy_filled,
            territory: job.territory,
            territory_description: job.territory_description,
            description: job.description,
            sub_description: job.sub_description,
            requirements: job.requirements,
            vacancy_description: job.vacancy_description,
            contact: job.contact,
            share: job.share,
            pipeline: job.pipeline,
            edges: job.edges,
            template: job.template,
            last_y: job.last_y,
            last_id: job.last_id,
            form: job.form,
            closer_val: job.closer_val,
            type: job.type,
            layout: job.layout
          });
        });
      }
      return jobData;
    }

    public async listPipelineFlows() {
      try {
        const pipelines = [] as Item[];
        const itemQuery = { 
          limit: -1,
        };
        const listPipelineFlows = await this.directus.items("directus_flows").readByQuery(itemQuery);
        if(listPipelineFlows.data)
        {
          listPipelineFlows.data.forEach((element: Item) => {
            if(element.name.includes("system-flow-")&&!element.name.includes("old.")) {
              pipelines.push({ id: element.id, name: element.name.replace("system-flow-",""), description: element.description });
            }
          });
        }
        return pipelines;
      } catch (error) {
        return [];
      }
    }

    public async listStatuses(id = '', status = 'published', stage = '') {
      try {
        const statuses = [] as Item[]; 
        const itemQuery = { 
          filter:{}, 
          limit: -1,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          sort: 'order' as any
        };
        if(id !== '')
        {
          itemQuery.filter = {
            id: {
              _eq: id,
            },
            status: {
              _eq: status,
            }
          }
        }
        else if(stage !== '') {
          itemQuery.filter = {
            stage: {
              _eq: stage,
            },
          }
        }
        else
        {
          itemQuery.filter = {
            status: {
              _eq: status,
            }
          }
        }
        const listStatuses = await this.directus.items("application_statuses").readByQuery(itemQuery);
        if(listStatuses.data)
        {
          listStatuses.data.forEach((element: Item) => {
            statuses.push(element);
          });
        }
        return statuses;
      } catch (error) {
        return [];
      }
    }

    public async createStatus(statusData:Item) {
      try {
        const result = await this.directus.items("application_statuses").createOne(statusData);
        return result;
      } catch (error) {
        return 'error';
      }
    }

    public async updateStatus(statusID:string,statusData:Item) {
      try {
        const result = await this.directus.items("application_statuses").updateOne(statusID,statusData);
        return result;
      } catch (error) {
        return 'error';
      }
    }

    public async listSystemCandidatesUnassigned() {
      const userData: Item[] = [];
      const itemQuery = { 
        fields:['*','user_role.*', 'territories.*', 'candidate_tags.*'],
        filter:{}, 
        limit: -1,
      };
      itemQuery.filter = {
        role: {
          _eq: '377da95c-6518-4715-b32d-ac9825cb42bd',
        },
        location: {
          _empty: true,
        },
        has_applied: {
          _eq: false,
        }
      };
      const users = await this.directus.items("directus_users").readByQuery(itemQuery);
      if(users.data) {
        users.data.forEach((user: Item) => {
          userData.push({
            id: user.id,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            middle_name: user.middle_name,
            full_name: user.first_name + ' ' + user.last_name,
            preferred_name: user.preferred_name,
            contact_number: user.contact_number,
            avatar: user.avatar,
            social_fb: user.social_fb,
            social_viber: user.social_viber,
            social_whatsapp: user.social_whatsapp,
            social_twitter: user.social_twitter,
            social_linkedin: user.social_linkedin,
            social_website: user.social_website,
            status: user.status,
            user_role: user.user_role,
            territories: user.territories,
            user_territories: [] as Array<string>,
            courtesy_title: user.courtesy_title,
            name_suffix: user.name_suffix,
            candidate_tags: user.candidate_tags,
          });
        });
      }
      return userData;
    }

    public async listSystemCandidates(id = '', status = 'active', allowNoTerritory = false) {
      const userData: Item[] = [];
      const itemQuery = { 
        fields:['*','user_role.*', 'territories.*', 'candidate_tags.*'],
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        if(allowNoTerritory===true)
        {
          itemQuery.filter = {
            id: {
              _eq: id,
            },
            status: {
              _eq: status,
            },
            role: {
              _eq: '377da95c-6518-4715-b32d-ac9825cb42bd',
            },
          }
        }else{
          itemQuery.filter = {
            id: {
              _eq: id,
            },
            status: {
              _eq: status,
            },
            role: {
              _eq: '377da95c-6518-4715-b32d-ac9825cb42bd',
            },
            territories: {
              _neq: null,
            }
  
          }
        }
      }
      else
      {
        const roleAccess=await this.returnRoleAccess();
        if(roleAccess.userRole==="System User") {
          console.log(roleAccess.userTerritories)
          itemQuery.filter = {
            "territories": {
              "territories_id": {
                "_in": roleAccess.userTerritories
              }
            },
            status: {
              _eq: status,
            },
            role: {
              _eq: '377da95c-6518-4715-b32d-ac9825cb42bd',
            }
          }
        } else {
          itemQuery.filter = {
            status: {
              _eq: status,
            },
            role: {
              _eq: '377da95c-6518-4715-b32d-ac9825cb42bd',
            },
            territories: {
              _neq: null,
            }
          }
        }
      }
      const users = await this.directus.items("directus_users").readByQuery(itemQuery);
      if(users.data) {
        users.data.forEach((user: Item) => {
          userData.push({
            id: user.id,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            middle_name: user.middle_name,
            full_name: user.first_name + ' ' + user.last_name,
            preferred_name: user.preferred_name,
            contact_number: user.contact_number,
            avatar: user.avatar,
            social_fb: user.social_fb,
            social_viber: user.social_viber,
            social_whatsapp: user.social_whatsapp,
            social_twitter: user.social_twitter,
            social_linkedin: user.social_linkedin,
            social_website: user.social_website,
            status: user.status,
            user_role: user.user_role,
            territories: user.territories,
            user_territories: [] as Array<string>,
            courtesy_title: user.courtesy_title,
            name_suffix: user.name_suffix,
            candidate_tags: user.candidate_tags,
          });
        });
      }
      return userData;
    }

    public async checkFieldData(candidateID:string,fieldID:string)
    {
      const itemQuery = { 
        filter:{ 
          field: {
            _eq: fieldID,
          },
          candidate: {
            _eq: candidateID,
          },
        }, 
        limit: 1,
      };
      const existingData = await this.directus.items("field_values").readByQuery(itemQuery);
      if (existingData.data) {
        const [dataId] = existingData.data as Item[];
        if(dataId?.id){
          return dataId?.id;
        } else {
          return "add";
        }
      } else {
        return "add";
      }
    }

    public async addFieldData(candidateID:string,fieldID:string,fieldValue:string) {
      console.log("ADDFIELD",fieldValue,fieldID)
      const dataInsert = { candidate:candidateID,field:fieldID,status:'published',value:fieldValue };
      const result = await this.directus.items("field_values").createOne(dataInsert);
      if(result){ return true; }else{ return false; }
    }

    public async updateFieldData(fieldID:string,fieldValue:string) {
      const dataInsert = { value:fieldValue };
      const result = await this.directus.items("field_values").updateOne(fieldID,dataInsert);
      if(result){ return true; }else{ return false; }
    }

    public async updateCandidate(candidateID:string,candidateData:Item,candidateFieldData:Item,noTerritory=true) {
      const exist = [];
      const added = [];
      const items = [];
      const values = [];
      for (const fieldID of Object.keys(candidateFieldData)) {
        const result = await this.checkFieldData(candidateID,fieldID);
        if(result === 'add'){ 
          if(await this.addFieldData(candidateID,fieldID,candidateFieldData[fieldID])){ ''; }
          items.push(fieldID); values.push(candidateFieldData[fieldID]);
          added.push(result);
        }else{
          if(await this.updateFieldData(result,candidateFieldData[fieldID])){ ''; }
          items.push(fieldID); values.push(candidateFieldData[fieldID]);
          exist.push(result);
        }
      }
      if(noTerritory){
        delete candidateData.territories
      }
      await this.directus.items("directus_users").updateOne(candidateID,candidateData);
      return { exist:exist, added:added, fieldIDs: items, candidateDatas:values }
    }

    public async returnCandidateFieldsData(candidateID:string) {
      const candidateFieldData: Item = {};
      const itemQuery = { 
        filter:{ 
          candidate: {
            _eq: candidateID,
          },
        }, 
        limit: -1,
      };
      const existingData = await this.directus.items("field_values").readByQuery(itemQuery);
      if (existingData.data) {
        existingData.data.forEach((data: Item) => {
          candidateFieldData[data.field]=data.value;
        });
      }
      return candidateFieldData;
    }

    public async returnCandidateFieldsDataAll(candidateID:string) {
      const candidateFieldData = [] as Item[];
      const itemQuery = { 
        filter:{ 
          candidate: {
            _eq: candidateID,
          },
        }, 
        limit: -1,
      };
      const existingData = await this.directus.items("field_values").readByQuery(itemQuery);
      if (existingData.data) {
        existingData.data.forEach((data: Item) => {
          candidateFieldData.push(data);
        });
      }
      return candidateFieldData;
    }

    public async returnCandidateApplications(candidateID:string) {
      const candidateApplications: Item[] = [];
      const itemQuery = { 
        fields: ['*.*'],
        filter:{ 
          candidate: {
            _eq: candidateID,
          },
        },
        limit: -1,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sort: '-date_created' as any
      };
      const existingData = await this.directus.items("candidate_applications").readByQuery(itemQuery);
      if (existingData.data) {
        existingData.data.forEach((data: Item) => {
          candidateApplications.push(data);
        });
      }
      return candidateApplications;
    }

    public async returnCandidateApplicationsByID(applicationID:string) {
      const candidateApplications: Item[] = [];
      const itemQuery = { 
        fields: ['*.*'],
        filter:{ 
          id: {
            _eq: applicationID,
          },
        },
        limit: -1,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sort: '-date_created' as any
      };
      const existingData = await this.directus.items("candidate_applications").readByQuery(itemQuery);
      if (existingData.data) {
        existingData.data.forEach((data: Item) => {
          candidateApplications.push(data);
        });
      }
      return candidateApplications;
    }

    public async returnCandidateApplicationPipelines(applicationID:string) {
      const candidateApplicationsPipes: Item[] = [];
      const itemQuery = { 
        fields: ['*','user_created.*'],
        filter:{ 
          application: {
            _eq: applicationID,
          },
        },
        limit: -1,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sort: '-date_created' as any
      };
      const existingData = await this.directus.items("candidate_application_pipelines").readByQuery(itemQuery);
      if (existingData.data) {
        existingData.data.forEach((data: Item) => {
          candidateApplicationsPipes.push(data);
        });
      }
      return candidateApplicationsPipes;
    }

    public async returnCandidateApplicationFlows(applicationID:string) {
      const candidateApplicationsPipes: Item[] = [];
      const itemQuery = { 
        fields: ['*','user_created.*'],
        filter:{ 
          _and: [
            {
                pipe_id: {
                  _nempty: true,
              }
            },
            {
              application: {
                _eq: applicationID,
              }
            }
          ]
        },
        limit: -1,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sort: '-date_created' as any
      };
      const existingData = await this.directus.items("application_task_scheduler").readByQuery(itemQuery);
      if (existingData.data) {
        existingData.data.forEach((data: Item) => {
          candidateApplicationsPipes.push(data);
        });
      }
      return candidateApplicationsPipes;
    }

    public async createStage(stageData:Item, statusData:Item) {
      try {
        const result = await this.directus.items("stages").createOne(stageData) as DirectusResponseId;
        statusData.forEach((status:Item) => {
          status.stage = result.id;
          this.directus.items("application_statuses").createOne(status)
        });
        return result;
      } catch (error) {
        return 'error';
      }
    }

    public async updateStage(stageID:string,stageData:Item, statusData:Item) {
      try {
        const result = await this.directus.items("stages").updateOne(stageID,stageData);
        const old_statuses_id = [] as Array<string>;
        const old_statuses = await this.listStatuses('','published',stageID);
        old_statuses.forEach((status:Item) => { old_statuses_id.push(status.id); });
        await this.directus.items("application_statuses").deleteMany(old_statuses_id)
        statusData.forEach((status:Item) => {
          status.stage = stageID;
          this.directus.items("application_statuses").createOne(status)
        });
        return result;
      } catch (error) {
        return 'error';
      }
    }

    public async listStages(id = '', status = 'published') {
      const stageData: Item[] = [];
      const itemQuery = { 
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        itemQuery.filter = {
          id: {
            _eq: id,
          },
        }
      }
      else
      {
        itemQuery.filter = {
          status: {
            _eq: status,
          }
        }
      }
      const stages = await this.directus.items("stages").readByQuery(itemQuery);
      if(stages.data) {
        stages.data.forEach((stage: Item) => {
          stageData.push({
            id: stage.id,
            name: stage.name,
            description: stage.description,
            color: stage.color,
          });
        });
      }
      return stageData;
    }

    public async getItemLogs(collection:string,collectionID:string) {
      const itemQuery = { 
        fields: ['*','*.*'],
        filter: {
          collection: {
            _eq: collection
          },
          item: {
            _eq: collectionID
          },
        },
        limit: -1,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sort: '-timestamp' as any,
        deep:true,
      };
      const logs = await this.directus.items("directus_activity").readByQuery(itemQuery);
      return logs.data;
    }

    public async createTemplateForms(jobData:Item) {
      try {
        const territories = jobData.territories;
        const newTerritories =[] as Item[];
        territories.forEach((territory: Item) => {
          newTerritories.push({ territories_id: territory })
        });
        delete jobData.territories;
        jobData.territories = newTerritories;
        await this.directus.items("job_forms").createOne(jobData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async updateTemplateForms(jobData:Item, templateID:string) {
      try {
        const territories = jobData.territories;
        const newTerritories =[] as Item[];
        territories.forEach((territory: Item) => {
          newTerritories.push({ territories_id: territory })
        });
        delete jobData.territories;
        jobData.territories = newTerritories;
        await this.directus.items("job_forms").updateOne(templateID,jobData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async listTemplateForms(id = '', status = 'published', territory = '') {
      const jobData: Item[] = [];
      const itemQuery = { 
        fields:['*', 'territories.*'],
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        itemQuery.filter = {
          id: {
            _eq: id,
          },
          status: {
            _eq: status,
          }
        }
      }
      else
      {
        if(territory !== '') {
          itemQuery.filter = {
            status: {
              _eq: status,
            },
            territory: {
              _has: territory,
            }
          }
        }
        else {
          const roleAccess=await this.returnRoleAccess();
          if(roleAccess.userRole==="System User") {
            itemQuery.filter = {
              "territories": {
                "territories_id": {
                  "_in": roleAccess.userTerritories
                }
              },
              status: {
                _eq: status,
              }
            }
          } else {
            itemQuery.filter = {
              status: {
                _eq: status,
              }
            }
          }
        }
      }
      const jobs = await this.directus.items("job_forms").readByQuery(itemQuery);
      if(jobs.data) {
        jobs.data.forEach((job: Item) => {
          jobData.push({
            id: job.id,
            status: job.status,
            name: job.name,
            description: job.description,
            template: job.template,
            territories: job.territories,
          });
        });
      }
      return jobData;
    }

    public async createTemplateRegisters(regData:Item) {
      try {
        if(regData.status==='published'){ 
          const regs = await this.listTemplateRegisters();
          regs.forEach((reg:Item) => {
            this.directus.items("system_registers").updateOne(reg.id,{ status:'draft' });
          });
        }
        await this.directus.items("system_registers").createOne(regData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async updateTemplateRegisters(regData:Item, templateID:string) {
      try {
        if(regData.status==='published'){ 
          const regs = await this.listTemplateRegisters();
          if(regs){
            regs.forEach((reg:Item) => {
              this.directus.items("system_registers").updateOne(reg.id,{ status:'draft' });
            });
            console.log(regs)
          }
        }
        console.log(templateID,regData)
        await this.directus.items("system_registers").updateOne(templateID,regData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async listTemplateRegisters(id = '', status = 'published') {
      const regData: Item[] = [];
      const itemQuery = { 
        fields:['*'],
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        itemQuery.filter = {
          id: {
            _eq: id,
          },
          status: {
            _eq: status,
          }
        }
      }
      else
      {
        itemQuery.filter = {
          status: {
            _eq: status,
          }
        }
      }
      const regs = await this.directus.items("system_registers").readByQuery(itemQuery);
      console.log(id,status,regs.data)
      if(regs.data) {
        regs.data.forEach((reg: Item) => {
          regData.push({
            id: reg.id,
            status: reg.status,
            name: reg.name,
            description: reg.description,
            template: reg.template,
          });
        });
      }
      return regData;
    }

    public async getActiveRegister() {
      const regData: Item[] = [];
      const itemQuery = { 
        fields:['*'],
        filter:{
          status: {
            _eq: 'published',
          }
        }, 
        limit: 1,
      };
      const regs = await this.directus.items("system_registers").readByQuery(itemQuery);
      if(regs.data) {
        regs.data.forEach((reg: Item) => {
          regData.push({
            id: reg.id,
            status: reg.status,
            name: reg.name,
            description: reg.description,
            template: reg.template,
          });
        });
      }
      return regData;
    }

    public async registerCandidate(
      userFields:Item, customFields:Item,
      email = '', password = '', autoActive = false
    ) 
    {
      await this.userLogin('tbpo-core@transparentbpo.com', 'r4mp3dUp@dm1n!');
      let validation_result = 'Success';
      userFields.email = email.toLowerCase();
      userFields.password = password;
      userFields.role = '377da95c-6518-4715-b32d-ac9825cb42bd'
      console.log('U-Fields',userFields)
      console.log('C-Fields',customFields)
      if(autoActive === true){
        userFields.otp_code = 'auto-active';
        userFields.autoactive = true;
        userFields.status = 'active';
      }else{
        userFields.otp_code = 'not-active';
      }
      try {
        const territories = userFields.territories;
        const newTerritories =[] as Item[];
        newTerritories.push({ territories_id: userFields.territories })
        /*territories.forEach((territory: Item) => {
          if(territory){
            newTerritories.push({ territories_id: territory })
          }
        });*/
        /*territories.forEach((territory: string) => {
          if(territory){
            newTerritories.push({ territories_id: territory })
          }
        });*/
        delete userFields.territories;
        userFields.territories = newTerritories;
        const registration = await this.directus.items('directus_users').createOne(userFields) as DirectusResponseId;
        if(!registration)
        {
          validation_result = 'Registration Failed';
        } else {
          if(customFields.length>0) {
            customFields.forEach((value:Item, index:string) => {
              console.log(`Index: ${index}, Value: ${value}`);
            });
          }
          if(registration.id){
            await this.updateCandidate(registration.id,userFields,customFields)
          }
        }
        await this.userLogout();
        return validation_result;
      } catch (error) {
        await this.userLogout();
        return 'Registration Failed:'+error;
      }
    }

    public async jobApplication(jobId:string,candidateId:string,fields:Item,customFields:Item,jobItem:Item) {
      try {
        const application_data = {
          application_status : 'Candidate Applied',
          job_post : jobId,
          candidate : candidateId,
        }
        console.log('DS - jobItem', jobItem)
        const jobTerritory = jobItem.territory.id;
        const myRecord = await this.returnMe();
        const newTerritories = [] as Item[];
        let newTerrID = "";
        myRecord.full_data.territories.forEach((terr:Item) => {
          if(terr.territories_id)
          {
            if(terr.territories_id!==""&&terr.territories_id!==undefined&&terr.territories_id!==null)
            {
              newTerritories.push({ territories_id: terr.territories_id})
              newTerrID = terr.territories_id;
            }
          }
        });
        if(jobItem.territory.id){
          const exists = newTerritories.some((obj:Item) => obj.territories_id === jobTerritory);
          //const index = newTerritories.findIndex((obj:Item) => obj.territories_id === jobTerritory);
          if(!exists){
            newTerritories.push({ territories_id: jobTerritory })
            newTerrID = jobTerritory;
          }
        }
        if(newTerrID!==""){
          let newLoc = "";
          const [newTerr] = await this.getTerritories(newTerrID)
          //HARD CODED
          /*
          Belize – Belmopan
          Belize – Seaside
          Belize – WFH Belize City
          Belize – WFH Belmopan
          Jamaica – Angels
          Philippines – Greenhills
          Philippines – WFH
          United States – Deployed
          United States – WFH
          India – WFH
          */
          if(newTerr.name==="Philippines"){
            newLoc = "Philippines - Greenhills";
          }
          else if(newTerr.name==="Jamaica"){
            newLoc = "Jamaica - Angels";
          }
          else if(newTerr.name==="Belize"){
            newLoc = "Belize - Belmopan";
          }
          fields.location = newLoc;
        }
        //console.log("jobTerritory",jobTerritory,index);
        fields.has_applied = true;
        console.log('DS - newTerritories', newTerritories)
        fields.territories = newTerritories;
        const result = await this.directus.items("candidate_applications").createOne(application_data) as Item;
        /**if(result.id) {
          const logData = {
            application: result.id,
            name: 'Candidate Applied',
            type: 'Initial',
            description: 'Candidate Applied',
            stage: 'Initial Stage',
          };
          //await this.directus.items("candidate_application_pipelines").createOne(logData);
        }*/
        await this.updateCandidate(candidateId,fields,customFields,false)
        //Fetch my Territories returnMe
        //Update my Territories using Job Location
        return result;
      } catch (error) {
        return 'error';
      }
    }

    public async myApplications(candidateId:string) {
      const appData: Item[] = [];
      const itemQuery = { 
        fields:['*'],
        filter:{
          candidate: {
            _eq: candidateId,
          }
        }, 
        sort: '-date_created' as any,
        limit: -1,
      };
      const apps = await this.directus.items("candidate_applications").readByQuery(itemQuery);
      if(apps.data) {
        apps.data.forEach((app: Item) => {
          appData.push({
            id: app.id,
            application_status: app.application_status,
            job_post: app.job_post,
            candidate: app.candidate,
            last_pipe: app.last_pipe,
            last_stage: app.last_stage,
            is_closed: app.is_closed,
          });
        });
      }
      return appData;
    }

    public async myDocuments(candidateId:string,folderType:string|undefined) {
      let folder='';
      if(folderType == 'resumes'){ folder = process.env.VUE_APP_DIRECTUS_API_FILE_FOLDER_RESUME; }
      if(folderType == 'offers'){ folder = process.env.VUE_APP_DIRECTUS_API_CANDIDATE_OFFERS; }
      if(folderType == 'documents'){ folder = process.env.VUE_APP_DIRECTUS_API_CANDIDATE_DOCUMENTS; }
      const fileData: Item[] = [];
      const itemQuery = { 
        fields:['*'],
        filter:{
          uploaded_by: {
            _eq: candidateId,
          },
          folder: {
            _eq: folder,
          }
        }, 
        limit: -1,
      };
      const files = await this.directus.items("directus_files").readByQuery(itemQuery);
      if(files.data) {
        files.data.forEach((app: Item) => {
          fileData.push(app);
        });
      }
      return fileData;
    }

    public async deleteMyDocument(documentID:string) {
      const deleted=this.directus.files.deleteOne(documentID);
      return deleted;
    }

    public async moveApplication(applicationID:string,data:Item) {
      const application_create_task = {
        type: "update status",
        execution: "immediate",
        data_input: data,
        application: applicationID,
        pipe_id: data.id,
      }
      try {
        const deleteItemsQuery = { 
          filter:{
            application: {
              _eq: applicationID,
            },
          }, 
          limit: -1,
        };
        const deleteItemIds = await this.directus.items("application_task_scheduler").readByQuery(deleteItemsQuery) as Item;
        const itemsToDelete = [] as Array<string>;
        deleteItemIds.data.forEach((element:Item) => {
          itemsToDelete.push(element.id);
        });
        await this.directus.items("application_task_scheduler").deleteMany(itemsToDelete);
        await this.directus.items("application_task_scheduler").createOne(application_create_task) as Item;
        return true;
      } catch (error) {
        return false;
      }
    }

    public async withdrawApplication(jobId:string,activeJobAppId:string,withdrawNotes:string) {
      const pipe_insert = {
        status: 'published',
        application: activeJobAppId,
        name: 'Withdrew',
        type: 'Withdrew',
        description: 'Candidate withdrew from application',
      };
      const application_update = {
        is_closed: true,
        application_status: 'Withdrew',
        notes: withdrawNotes,
      }
      try {
        await this.directus.items("candidate_application_pipelines").createOne(pipe_insert) as Item;
        await this.directus.items("candidate_applications").updateOne(activeJobAppId,application_update) as Item;
        return true;
      } catch (error) {
        return false;
      }
    }

    public async sendMessage(applicationId:string,message:string,sender:string,job_post:string,territory:string,to:string) {
      const sendMessage = await this.directus.items("candidate_application_messages").createOne({ candidate_application:applicationId, message:message, sender:sender, job_post:job_post, territory:territory, to:to });
      return sendMessage;
    }

    public async readMessage(applicationId:string) {
      const itemQuery = { 
        fields:['*'],
        filter:{
          candidate_application: {
            _eq: applicationId,
          },
        }, 
        limit: -1,
      };
      const messages = await this.directus.items("candidate_application_messages").readByQuery(itemQuery);
      const ids =  [] as Array<string>
      messages.data?.forEach(element => {
        ids.push(element.id);
      });
      const readMessage = await this.directus.items("candidate_application_messages").updateMany(ids,{ seen:true }) as Item;
      return readMessage;
    }

    public async getMessages(applicationId:string) {
      const messageData: Item[] = [];
      const itemQuery = { 
        fields:['*'],
        filter:{
          candidate_application: {
            _eq: applicationId,
          },
        }, 
        limit: -1,
      };
      const messages = await this.directus.items("candidate_application_messages").readByQuery(itemQuery);
      if(messages.data) {
        messages.data.forEach((app: Item) => {
          messageData.push(app);
        });
      }
      return messageData;
    }

    public async getApplicants(jobID:string,closer_status=false) {
      const applicants: Item[] = [];
      const applicationData: Item[] = [];
      const applicantIDs = [] as Array<string>;
      const itemQuery = { 
        fields:['*','candidate.*'],
        filter:{
          job_post: {
            _eq: jobID,
          },
          is_closed: {
            _eq: closer_status,
          },
        }, 
        limit: -1,
      };
      const applications = await this.directus.items("candidate_applications").readByQuery(itemQuery);
      if(applications.data) {
        applications.data.forEach((app: Item) => {
          app.application_id = app.id;
          app.date_updated = this.returnFormattedDate(app.date_updated);
          app.date_created = this.returnFormattedDate(app.date_created);
          app.candidate_id = app.candidate.id;
          applicants[app.candidate.id]=app;
          applicantIDs.push(app.candidate.id);
          applicationData.push({ ...app, ...app.candidate });
        });
      }
      return applicationData;
    }

    public async getApplication(applicationID:string) {
      const itemQuery = { 
        fields:['*.*'],
        filter:{
          id: {
            _eq: applicationID,
          },
        }, 
        limit: -1,
      };
      const application = await this.directus.items("candidate_applications").readByQuery(itemQuery);
      if(application.data){
        return application.data;
      } else {
        return [];
      }
    }

    public async getTasks(applicationID:string) {
      const itemQuery = { 
        fields:['*.*'],
        filter:{
          application: {
            _eq: applicationID,
          },
        }, 
        limit: -1,
      };
      const application = await this.directus.items("application_task_scheduler").readByQuery(itemQuery);
      if(application.data){
        return application.data;
      } else {
        return [];
      }
    }

    public async getOffers(applicationID:string) {
      const itemQuery = { 
        fields:['*.*'],
        filter:{
          application: {
            _eq: applicationID,
          },
        }, 
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sort: '-date_created' as any,
        limit: -1,
      };
      const offer = await this.directus.items("offers").readByQuery(itemQuery);
      if(offer.data){
        return offer.data;
      } else {
        return [];
      }
    }

    public async getOffer(applicationID:string,job_post:string,candidate:string,unique_id:string) {
      const itemQuery = { 
        fields:['*.*'],
        filter:{
          application: {
            _eq: applicationID,
          },
          job_post: {
            _eq: job_post,
          },
          candidate: {
            _eq: candidate,
          },
          task_uuid: {
            _eq: unique_id,
          },
        }, 
        limit: -1,
      };
      const offer = await this.directus.items("offers").readByQuery(itemQuery);
      if(offer.data){
        return offer.data;
      } else {
        return [];
      }
    }

    public async updateOffer(offerID:string,status:string,offerTemplate:Item) {
      try {
        if(status!=="sent"){
          const result = await this.directus.items("offers").updateOne(offerID,{ offer_status:status });
          return result;
        }else{
          const result = await this.directus.items("offers").updateOne(offerID,{ offer_status:status, pdf_template:offerTemplate, });
          return result;
        }
      } catch (error) {
        return 'error';
      }
    }

    public async declineOffer(offerID:string) {
      try {
        const result = await this.directus.items("offers").updateOne(offerID,{ offer_status:'declined' });
        return result;
      } catch (error) {
        return 'error';
      }
    }

    public async acceptOffer(offerID:string,signature_type:string,signature_data:Item,signature_content:string,pdf_template:string) {
      try {
        const result = await this.directus.items("offers").updateOne(offerID,{ 
          offer_status:'accepted',
          signature_type: signature_type,
          signature_data: signature_data,
          signature_content: signature_content,
          pdf_template: pdf_template
         });
        return result;
      } catch (error) {
        return 'error';
      }
    }

    public async getConsentById(consentID:string) {
      await this.userLogin('ramp-system-registration@transparentbpo.com', 'r3g!str!@co3ddgj2884hdb123jDgjhifndi');
      const itemQuery = { 
        fields:['*.*'],
        filter:{
          id: {
            _eq: consentID,
          },
        }, 
        limit: -1,
      };
      const consent = await this.directus.items("consents").readByQuery(itemQuery);
      await this.userLogout();
      if(consent.data){
        return consent.data;
      } else {
        return [];
      }
    }
    
    public async getConsent(applicationID:string,job_post:string,candidate:string,unique_id:string) {
      const itemQuery = { 
        fields:['*.*'],
        filter:{
          application: {
            _eq: applicationID,
          },
          job_post: {
            _eq: job_post,
          },
          candidate: {
            _eq: candidate,
          },
          task_uuid: {
            _eq: unique_id,
          },
        }, 
        limit: -1,
      };
      const consent = await this.directus.items("consents").readByQuery(itemQuery);
      if(consent.data){
        return consent.data;
      } else {
        return [];
      }
    }

    public async updateConsent(consentID:string,status:string,consentTemplate:Item) {
      try {
        if(status!=="sent"){
          const result = await this.directus.items("consents").updateOne(consentID,{ consent_status:status });
          return result;
        }else{
          const result = await this.directus.items("consents").updateOne(consentID,{ consent_status:status, pdf_template:consentTemplate, });
          return result;
        }
      } catch (error) {
        return 'error';
      }
    }

    public async declineConsent(consentID:string) {
      try {
        const result = await this.directus.items("consents").updateOne(consentID,{ consent_status:'declined' });
        return result;
      } catch (error) {
        return 'error';
      }
    }

    public async acceptConsent(consentID:string,signature_type:string,signature_data:Item,signature_content:string,pdf_template:string, attachment_1:File[] | undefined, attachment_2:File[] | undefined) {
      try {
        let attachment_1_url = "";
        let attachment_2_url = "";
        if(attachment_1 !== undefined) {
          const uploadedAttachment = await this.uploadFile(attachment_1,'consents_1');
          if(uploadedAttachment)
          {
            attachment_1_url=uploadedAttachment;
          }
        }
        if(attachment_2 !== undefined) {
          const uploadedAttachment = await this.uploadFile(attachment_2,'consents_2');
          if(uploadedAttachment)
          {
            attachment_2_url=uploadedAttachment;
          }
        }
        const result = await this.directus.items("consents").updateOne(consentID,{ 
          consent_status:'accepted',
          signature_type: signature_type,
          signature_data: signature_data,
          signature_content: signature_content,
          pdf_template: pdf_template,
          attachment_1: attachment_1_url,
          attachment_2: attachment_2_url,
         });
        return result;
      } catch (error) {
        return 'error';
      }
    }

    public async listButtons(id = '', status = 'published', stage = '') {
      const buttonData: Item[] = [];
      if(id !== "Initial Stage"&&stage !== "Initial Stage"){
        const itemQuery = { 
          fields:['*'],
          filter:{}, 
          limit: -1,
        };
        if(id !== '')
        {
          itemQuery.filter = {
            id: {
              _eq: id,
            },
            status: {
              _eq: status,
            }
          }
        }
        else if(stage !== '')
        {
          itemQuery.filter = {
            stage: {
              _eq: stage,
            },
            status: {
              _eq: status,
            }
          }
        }
        else
        {
          itemQuery.filter = {
            status: {
              _eq: status,
            }
          }
        }
        const buttons = await this.directus.items("buttons").readByQuery(itemQuery);
        if(buttons.data) {
          buttons.data.forEach((button: Item) => {
            buttonData.push({
              id: button.id,
              status: button.status,
              name: button.name,
              description: button.description,
              button_url: button.button_url,
              stage: button.stage,
            });
          });
        }
      }
      return buttonData;
    }

    public async createButtons(buttonData:Item) {
      try {
        await this.directus.items("buttons").createOne(buttonData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async updateButtons(buttonData:Item, buttonID:string) {
      try {
        await this.directus.items("buttons").updateOne(buttonID,buttonData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public returnFormattedDate(timestamp:string) {
        const date = new Date(timestamp);
        // Extract date and time components
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month (0-11)
        const day = String(date.getDate()).padStart(2, '0'); // Day of the month (1-31)
        const year = date.getFullYear(); // Year (4 digits)
        const hours = String(date.getHours()).padStart(2, '0'); // Hours (0-23)
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Minutes (0-59)
        const seconds = String(date.getSeconds()).padStart(2, '0'); // Seconds (0-59)

        // Create the formatted string
        const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
    }

    public async getApplicationStats(job_id:string) {
      let app_current = 0;
      let app_concluded = 0;
      try {
        const itemQuery = { 
          fields:['id','is_closed'],
          filter:{
            job_post: {
              _eq: job_id,
            },
          }, 
          limit: -1,
        };
        const application = await this.directus.items("candidate_applications").readByQuery(itemQuery);
        if(application.data){
          application.data.forEach((appData: Item) => {
            if(appData.is_closed){
              app_concluded++;
            }else{
              app_current++;
            }
          });
          return { app_current:app_current,app_concluded:app_concluded};
        } else {
          return { app_current:app_current,app_concluded:app_concluded};
        }
      } catch (error) {
        return { app_current:app_current,app_concluded:app_concluded};
      }
    }

    public async getTasksStats(job_id:string) {
      let needs_action = 0;
      const already_added = [] as Array<string>;
      try {
        const itemQuery = { 
          fields:['*.*'],
          filter:{
            application: {
              job_post: {
                _eq: job_id,
              },
              is_closed: {
                _eq: false,
              },
            },
            task_status: {
              _eq: 'opened',
            },
            type: {
              _in: ['decision','offer','consent'],
            },
          }, 
          limit: -1,
        };
        const application = await this.directus.items("application_task_scheduler").readByQuery(itemQuery);
        if(application.data){
          if(application.data){
            application.data.forEach((appData: Item) => {
              if(already_added.includes(appData.application.id))
              {
                //Do nothing since its already counted...
              }else{
                already_added.push(appData.application.id)
                needs_action++;
              }
            })
          }
          return { needs_action:needs_action };
        } else {
          return { needs_action:needs_action };
        }
      } catch (error) {
        return { needs_action:needs_action };
      }
    }

    public async searchRecord(keyword:string,roleId='') {
      const userData: Item[] = [];
      const jobData: Item[] = [];

      const role_id='377da95c-6518-4715-b32d-ac9825cb42bd';

      if(roleId!=="377da95c-6518-4715-b32d-ac9825cb42bd")
      {
        const itemQuery = { 
          fields:['*','user_role.id', 'territories.*'],
          filter:{
            status: {
              _eq: 'active',
            },
            role: {
              _eq: role_id,
            }
          }, 
          search: keyword,
          limit: 5,
        };
        const users = await this.directus.items("directus_users").readByQuery(itemQuery);
        if(users.data) {
          users.data.forEach(async (user: Item) => {
            const itemQueryCustom = { 
              fields:['*','job_post.title','job_post.id','candidate.id'],
              filter:{
                candidate: {
                  id: {
                    _eq: user.id,
                  }
                },
              },
              sort: ['-date_created'],
              limit: 1,
            } as Item;
            user.application = await this.directus.items("candidate_applications").readByQuery(itemQueryCustom)
            userData.push(user);
          });
        }
      }
      
      const itemQuery2 = { 
        fields:['*'],
        filter:{
          status: {
            _eq: 'published',
          },
        }, 
        search: keyword,
        limit: 5,
      };
      const jobs = await this.directus.items("job_posts").readByQuery(itemQuery2);
      if(jobs.data) {
        jobs.data.forEach((job: Item) => {
          jobData.push(job);
        });
      }

      return { candidates:userData, jobs:jobData };
    }

    public async applicationsReport(isAdmin=false,territories:Array<string>,startDateStr:string,endDateStr:string) {
      const appData: Item[] = [];
      const startDate = new Date(startDateStr);
      const endDate = new Date(endDateStr);
      const itemQuery = { 
        fields:['*','job_post.title','job_post.type','job_post.status','job_post.territory'],
        filter:{}, 
        sort: '-date_created' as any,
        limit: -1,
      };
      if(isAdmin===false){
        itemQuery.filter = { 
          job_post: {
            territory: {
              id: {
                _in:territories 
              }
            } 
          },
          date_created: {
            _between: [startDate.toISOString(), endDate.toISOString()]
          }
        };
      }else{
        itemQuery.filter = { 
          date_created: {
            _between: [startDate.toISOString(), endDate.toISOString()]
          }
        };
      }
      const apps = await this.directus.items("candidate_applications").readByQuery(itemQuery);
      if(apps.data) {
        apps.data.forEach((app: Item) => {
          appData.push(app);
        });
      }
      return appData;
    }
    public async createTemplateSurveys(surveyData:Item) {
      try {
        const questionnaireData = [] as Item[];;
        surveyData.questionnairesPP.forEach((questInfo: Item) => {
          questionnaireData.push({ interview_survey_questionnaires_id: questInfo.id })
        });
        surveyData.questionnaires = questionnaireData;
        await this.directus.items("interview_survey").createOne(surveyData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async updateTemplateSurveys(surveyData:Item, surveyID:string) {
      try {
        const questionnaireData = [] as Item[];;
        surveyData.questionnairesPP.forEach((questInfo: Item) => {
          questionnaireData.push({ interview_survey_questionnaires_id: questInfo.id })
        });
        surveyData.questionnaires = questionnaireData;
        await this.directus.items("interview_survey").updateOne(surveyID,surveyData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async listTemplateSurveys(id = '', status = 'published') {
      const terrQuery = { 
        fields:['*'],
        limit: -1,
      };
      const territoryAvailable = [] as Item;
      const territoryList = await this.directus.items("territories").readByQuery(terrQuery);
      if(territoryList.data) {
        territoryList.data.forEach((territory: Item) => {
          territoryAvailable.push({
            id: territory.id,
            name: territory.name,
          })
        })
      }
      const questionData: Item[] = [];
      const itemQuery = { 
        fields:['*', 'territories.*', 'questionnaires.*'],
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        itemQuery.filter = {
          id: {
            _eq: id,
          },
          status: {
            _eq: status,
          }
        }
      }
      else
      {
        const roleAccess=await this.returnRoleAccess();
        if(roleAccess.userRole==="System User") {
          itemQuery.filter = {
            "territories": {
              "territories_id": {
                "_in": roleAccess.userTerritories
              }
            },
            status: {
              _eq: status,
            }
          }
        } else {
          itemQuery.filter = {
            status: {
              _eq: status,
            }
          }
        }
      }
      const emails = await this.directus.items("interview_survey").readByQuery(itemQuery);
      if(emails.data) {
        emails.data.forEach((email: Item) => {
          questionData.push(email);
        });
      }
      return questionData;
    }

    public async createTemplateQuestionnaires(questionnareData:Item) {
      try {
        await this.directus.items("interview_survey_questionnaires").createOne(questionnareData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async updateTemplateQuestionnaires(questionData:Item, questionID:string) {
      try {
        await this.directus.items("interview_survey_questionnaires").updateOne(questionID,questionData);
        return true;
      } catch (error) {
        return false;
      }
    }

    public async listTemplateQuestionnaires(id = '', status = 'published', ids = [] as Array<string>) {
      const terrQuery = { 
        fields:['*'],
        limit: -1,
      };
      const territoryAvailable = [] as Item;
      const territoryList = await this.directus.items("territories").readByQuery(terrQuery);
      if(territoryList.data) {
        territoryList.data.forEach((territory: Item) => {
          territoryAvailable.push({
            id: territory.id,
            name: territory.name,
          })
        })
      }
      const questionData: Item[] = [];
      const itemQuery = { 
        fields:['*', 'territories.*'],
        filter:{}, 
        limit: -1,
      };
      if(id !== '')
      {
        itemQuery.filter = {
          id: {
            _eq: id,
          },
          status: {
            _eq: status,
          }
        }
      }
      else if(ids.length>0)
        {
          itemQuery.filter = {
            id: {
              _in: ids,
            },
            status: {
              _eq: status,
            }
          }
        }
      else
      {
        const roleAccess=await this.returnRoleAccess();
        if(roleAccess.userRole==="System User") {
          itemQuery.filter = {
            "territories": {
              "territories_id": {
                "_in": roleAccess.userTerritories
              }
            },
            status: {
              _eq: status,
            }
          }
        } else {
          itemQuery.filter = {
            status: {
              _eq: status,
            }
          }
        }
      }
      const emails = await this.directus.items("interview_survey_questionnaires").readByQuery(itemQuery);
      if(emails.data) {
        emails.data.forEach((email: Item) => {
          questionData.push(email);
        });
      }
      return questionData;
    }

    public async fetchRequiredSurvey(application = '', survey_status = 'incomplete') {
      const surveyData: Item[] = [];
      const itemQuery = { 
        fields:['*', 'application.*', 'survey.*', 'survey.questionnaires.*'],
        filter:{}, 
        limit: -1,
      };
      itemQuery.filter = {
        application: {
          id: {
            _eq: application,
          },
        },
        survey_status: {
          _eq: survey_status,
        }
      }
      const surveys = await this.directus.items("interview_survey_scores").readByQuery(itemQuery);
      if(surveys.data) {
        await Promise.all(surveys.data.map(async (item) => {
          let questGroup = [] as Array<string>;
          item.survey.questionnaires.forEach((quest: Item) => {
            questGroup.push(quest.interview_survey_questionnaires_id);
          });
          item.questionnairesDetail = await this.listTemplateQuestionnaires('', 'published', questGroup)
          surveyData.push(item);
          //await asyncFunction(item);
        }));
        /*surveys.data.forEach((item: Item) => {
          let questGroup = [] as Array<string>;
          item.survey.questionnaires.forEach((quest: Item) => {
            questGroup.push(quest.interview_survey_questionnaires_id);
          });
          item.questionnairesDetail = this.listTemplateQuestionnaires('', 'published', questGroup)
          surveyData.push(item);
        });*/
      }
      return surveyData;
    }

    public async updateSurveyData(surveyData:Item, surveyID:string) {
      try {
        await this.directus.items("interview_survey_scores").updateOne(surveyID,surveyData);
        return true;
      } catch (error) {
        return false;
      }
    }
}

export default DirectusDataService;