import { createApp } from 'vue'
import { Item } from "@directus/sdk";
import App from './App.vue'
import router from './routers'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Global
import '@/assets/styles/main.css'
import '@mdi/font/css/materialdesignicons.css'

// Services
import DirectusDataService from './services/DirectusDataService'

/* add icons to the library */
import { mdi, aliases } from 'vuetify/iconsets/mdi'

// Add Types
import Location from "@/types/Location";
import JobCategory from "@/types/JobCategory";
import JobSetup from "@/types/JobSetup";
import JobType from "@/types/JobType";

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $dataService: DirectusDataService;
    $jobProperties: {
      locations: Location[],
      jobSetups: JobSetup[],
      jobCategories: JobCategory[],
      jobTypes: JobType[],
    };
    $globals: {
      userData: Item,
      userChatNotifications: Item,
    }
  }
}

// Initialize Directus Service
const directusService = new DirectusDataService(process.env.VUE_APP_DIRECTUS_API_URL);

const initializeApp = async () => {
  const userData = await directusService.returnMe();
  const userChatNotifications = await directusService.returnChatNotifications(userData);
  const app = createApp(App);
  // Initialize Repeating Data
  app.config.globalProperties.$dataService = directusService;
  app.config.globalProperties.$jobProperties = {
    locations : [],
    jobSetups : [],
    jobCategories : [],
    jobTypes : [],
  };
  app.config.globalProperties.$globals = {
    userData: userData,
    userChatNotifications: userChatNotifications,
  }
  app.use(router)
   .use(vuetify)
   .mount('#app');
};

initializeApp();